import { DeleteTwoTone, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, App, Button, Col, Divider, Drawer, Form, InputNumber, Row, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useResetFormOnCloseModal from '../../shared/hooks/useResetFormModalOnClose';
import AddMerchDrawer, { NewMerchItem } from './AddMerchDrawer';

export interface NewPackageItem {
  key?: string;
  count?: number;
  weight: number;
  dimensionsW: number;
  dimensionsH: number;
  dimensionsL: number;
  merchItems: MerchItem[];
  basePrice: number;
  extraPrice: number;
  totalPrice: number;
}

interface AddMerchProps {
  preselectedPackage?: NewPackageItem;
  calculatePriceBasedOnAddressess: () => number;
  open: boolean;
  onCreate: (merch: NewPackageItem) => void;
  onCancel: () => void;
}

interface MerchItem {
  key: string;
  count: number;
  productKey: string;
  productName: string;
  unitKey: string;
  amount: number;
  weight: number;
  merchDesc: string;
}

export default function AddPackageDrawer({
  open,
  preselectedPackage,
  calculatePriceBasedOnAddressess,
  onCreate,
  onCancel,
}: AddMerchProps) {
  const [form] = Form.useForm<NewPackageItem>();
  const [merchItems, setMerchItems] = useState<MerchItem[]>([]);
  const [preselectedMerch, setPreselectedMerch] = useState<MerchItem | undefined>();
  const [addMerchDrawerOpen, setAddMerchDrawerOpen] = useState(false);
  const [basePrice, setbasePrice] = useState(0);
  const [extraPrice, setextraPrice] = useState(0);
  const [totalPrice, settotalPrice] = useState(0);
  const [wontTake, setWontTake] = useState(false);
  const { message } = App.useApp();
  const dimensionsW = Form.useWatch('dimensionsW', form);
  const dimensionsH = Form.useWatch('dimensionsH', form);
  const dimensionsL = Form.useWatch('dimensionsL', form);
  useResetFormOnCloseModal({ form, open });

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setMerchItems([]);
    }
  }, [open]);

  useEffect(() => {
    if (preselectedPackage) {
      form.setFieldsValue({
        weight: preselectedPackage.weight,
        dimensionsW: preselectedPackage.dimensionsW,
        dimensionsH: preselectedPackage.dimensionsH,
        dimensionsL: preselectedPackage.dimensionsL,
      });
      setMerchItems(preselectedPackage.merchItems);
    }
  }, [preselectedPackage]);

  useEffect(() => {
    // Update the total weight for the package
    const totalWeight = merchItems.reduce((prev, curr) => prev + curr.weight, 0);
    form.setFieldsValue({ weight: totalWeight });
    recalculatePackagePrice();
  }, [merchItems]);

  useEffect(() => {
    if (!dimensionsW || !dimensionsH || !dimensionsL) return;
    recalculatePackagePrice();
  }, [dimensionsW, dimensionsH, dimensionsL]);

  const recalculatePackagePrice = () => {
    const priceByWeight = merchItems.reduce((prev, curr) => prev + curr.weight, 0);
    const priceByPackageVolume = ((dimensionsH || 0) * (dimensionsL || 0) * (dimensionsW || 0)) / 5000;
    const totalPrice =
      priceByWeight > priceByPackageVolume ? Math.round(priceByWeight) : Math.round(priceByPackageVolume);

    // If the totalPrice is greater than 27, the following rules apply:
    // 1. If the totalPrice is greater than 27 and less or equial than 60, the final price will increase by 5 pesos for each kg in excess of 27.
    // 2. If the totalPrice is greater than 60, and less or equal than 80, the final price will increase by 6 pesos for each kg in excess of 27.
    // 3. If the totalPrice is greater than 80, they wont be able to recieve the package.
    let extraPrice = 0;
    if (totalPrice > 27 && totalPrice <= 60) {
      extraPrice = (totalPrice - 27) * 5;
      setWontTake(false);
    } else if (totalPrice > 60 && totalPrice <= 80) {
      extraPrice = (totalPrice - 27) * 6;
      setWontTake(false);
    } else if (totalPrice > 80) {
      extraPrice = (totalPrice - 27) * 6;
      setWontTake(true);
    }

    const priceByAddressess = calculatePriceBasedOnAddressess();
    setextraPrice(extraPrice);
    setbasePrice(priceByAddressess);
    settotalPrice(priceByAddressess + extraPrice);
    form.setFieldValue('price', priceByAddressess + extraPrice);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      // Check that we must have at least one merch item
      if (!merchItems.length) {
        message.warning('Ingresa al menos una mercancía');
        return;
      }
      if (preselectedPackage) {
        onCreate({
          ...values,
          key: preselectedPackage.key,
          count: preselectedPackage.count,
          merchItems,
          totalPrice,
          extraPrice,
          basePrice,
        });
      } else {
        onCreate({ ...values, merchItems, totalPrice, extraPrice, basePrice });
      }
    } catch (e) {}
  };

  const handleMerchRemove = (merch: MerchItem) => {
    const newItems = merchItems.filter((item) => item.key !== merch.key).map((item, k) => ({ ...item, count: k + 1 }));
    setMerchItems(newItems);
  };

  const handleMerchAdd = (merch: NewMerchItem) => {
    if (merch.key) {
      // Update it
      const newItems = merchItems.map((item, k) =>
        item.key === merch.key ? { ...merch, count: k + 1, key: merch.key } : { ...item, count: k + 1 },
      );
      setMerchItems(newItems);
      setAddMerchDrawerOpen(false);
      setPreselectedMerch(undefined);
    } else {
      const newMerch = { ...merch, key: uuidv4() };
      const newItems = [...merchItems, newMerch].map((item, k) => ({ ...item, count: k + 1 }));
      setMerchItems(newItems);
      setAddMerchDrawerOpen(false);
      setPreselectedMerch(undefined);
    }
  };

  const columns: ColumnsType<MerchItem> = [
    {
      title: '#',
      dataIndex: 'count',
      align: 'center',
    },
    {
      title: 'Clave de los bienes',
      dataIndex: 'productName',
    },
    {
      title: 'Descripción',
      dataIndex: 'merchDesc',
    },
    {
      title: 'Cantidad',
      dataIndex: 'amount',
      align: 'center',
    },
    {
      title: 'Peso',
      dataIndex: 'weight',
      align: 'right',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      align: 'right',
      render: (_, record) => (
        <Space size={'middle'}>
          <EditOutlined
            onClick={() => {
              setPreselectedMerch(record);
              setTimeout(() => {
                setAddMerchDrawerOpen(true);
              }, 10);
            }}
          />
          <DeleteTwoTone twoToneColor={'#cf1322'} onClick={() => handleMerchRemove(record)} />
        </Space>
      ),
    },
  ];

  return (
    <Drawer
      open={open}
      title="Agregar paquete"
      style={{ top: 20 }}
      width={800}
      destroyOnClose
      maskClosable={false}
      closable={false}
      extra={
        <Space>
          <Button
            key="back"
            onClick={() => {
              onCancel();
              setMerchItems([]);
            }}
          >
            Cancelar
          </Button>
          <Button key="submit" htmlType="submit" type="primary" disabled={wontTake} onClick={handleSubmit}>
            Agregar
          </Button>
        </Space>
      }
    >
      <Form form={form} layout="vertical" name="add-package-form" scrollToFirstError autoComplete="off">
        <Divider style={{ marginTop: 0 }}>Mercancías</Divider>
        <Row>
          <Col span={24}>
            <Table
              rowClassName={() => 'override-row-padding-8'}
              pagination={false}
              columns={columns}
              dataSource={merchItems}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col span={24}>
            <Button type="dashed" onClick={() => setAddMerchDrawerOpen(true)} block icon={<PlusOutlined />}>
              Agregar mercancía
            </Button>
          </Col>
        </Row>
        <Divider>Detalle del paquete</Divider>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name={'dimensionsW'}
              label="Ancho del paquete en cm"
              rules={[{ required: true, message: 'Ingresa el ancho del paquete en cm' }]}
            >
              <InputNumber style={{ width: '100%' }} min={0} precision={0} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'dimensionsL'}
              label="Largo del paquete en cm"
              rules={[{ required: true, message: 'Ingresa el largo del paquete en cm' }]}
            >
              <InputNumber style={{ width: '100%' }} min={0} precision={0} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'dimensionsH'}
              label="Altura del paquete en cm"
              rules={[{ required: true, message: 'Ingresa la altura del paquete en cm' }]}
            >
              <InputNumber style={{ width: '100%' }} min={0} precision={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name={'weight'}
              label="Peso en Kg"
              rules={[{ required: true, message: 'Ingresa el peso en kg', type: 'number' }]}
            >
              <InputNumber style={{ width: '100%' }} disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'price'}
              label="Precio del paquete"
              rules={[{ required: true, message: 'Ingresa el precio', type: 'number' }]}
            >
              <InputNumber
                formatter={(value) => `$ ${value!}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        {extraPrice > 0 && (
          <Row>
            <Col span={24}>
              <Alert
                message="Ten en cuenta que..."
                description={
                  wontTake ? (
                    `El peso o dimensiones del paquete exceden nuestros límites máximos, por lo tanto no podremos llevar este paquete`
                  ) : (
                    <span>
                      Debido al peso o dimensiones del paquete, se incluyó un cargo extra de
                      <strong> ${extraPrice}.00 MXN</strong>
                    </span>
                  )
                }
                type="warning"
                showIcon
              />
            </Col>
          </Row>
        )}
      </Form>

      <AddMerchDrawer
        open={addMerchDrawerOpen}
        onCancel={() => {
          setPreselectedMerch(undefined);
          setAddMerchDrawerOpen(false);
        }}
        onCreate={handleMerchAdd}
        preselectedMerch={preselectedMerch}
      />
    </Drawer>
  );
}
