import { App as AntApp } from 'antd';
import { Amplify } from 'aws-amplify';
import { Route, Routes } from 'react-router-dom';
import AuthProvider from './components/auth/AuthProvider';
import ProtectedLayout from './components/auth/ProtectedLayout';
import LoginPage from './components/auth/login/LoginPage';
import NotFound from './components/notFound/NotFound';
import PickupPanel from './components/pickup/PickupPanel';
import { amplifyConfig } from './config';
Amplify.configure(amplifyConfig);

function App() {
  return (
    <AntApp>
      <AuthProvider>
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route element={<ProtectedLayout />}>
            <Route path="envios" element={<PickupPanel />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </AntApp>
  );
}

export default App;
