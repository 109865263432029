import axios, { purgeQueryParams } from './axios';
import { components, paths } from './schema';

export const createShipment = async (
  body: components['schemas']['CreateShipmentRequest'],
): Promise<components['schemas']['CreateShipmentResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateShipmentResponse']>(`/user-client/shipment`, body);
  return data.data.data;
};

export const getShipingLabel = async (
  shipmentId: string,
): Promise<components['schemas']['GetShipmentLabelResponse']['data']> => {
  const data = await axios.get<components['schemas']['GetShipmentLabelResponse']>(
    `/user-client/shipment/${shipmentId}/create-label`,
  );
  return data.data.data;
};

export const getDefaultsSAT = async (): Promise<components['schemas']['GetDefaultsSATResponse']['data']> => {
  const data = await axios.get<components['schemas']['GetDefaultsSATResponse']>(
    `/user-client/shipment/defaults-for-sat`,
  );
  return data.data.data;
};

export const getShipments = async (
  params: paths['/user-client/shipment/list']['get']['parameters']['query'],
): Promise<components['schemas']['ClientShipmentListResponse']['data']> => {
  const data = await axios.get<components['schemas']['ClientShipmentListResponse']>(
    `/user-client/shipment/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const prepareShipmentCreate = async (): Promise<
  components['schemas']['ClientShipmentPrepareResponse']['data']
> => {
  const data = await axios.get<components['schemas']['ClientShipmentPrepareResponse']>(
    `/user-client/shipment/prepare-create`,
  );
  return data.data.data;
};
