import { Button, Col, Row, Typography } from 'antd';
import AppSvg from '../../AppSvg';
const { Title } = Typography;

export default function NotFound() {
  return (
    <div>
      <AppSvg />
      <Row style={{ minHeight: '100vh', textAlign: 'center' }} justify="center" align="middle">
        <Col span={4} xs={24}>
          <Title>Oops!</Title>
          <Title level={3}>La página que buscabas no existe</Title>
          <Button type="primary" size="large" href="/envios">
            Ir al inicio
          </Button>
        </Col>
      </Row>
    </div>
  );
}
