import { Col, Layout, Menu, Row, Space, Spin } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { PoweroffOutlined } from '@ant-design/icons';

const { Header, Content } = Layout;
interface MenuItem {
  label: ReactElement;
  key: React.Key;
  children?: MenuItem[];
}
const menuItems: MenuItem[] = [
  {
    label: <a href={'/envios'}>Mis envíos</a>,
    key: 'envios',
  },
];

export default function ProtectedLayout() {
  const { user, loading, logout } = useAuth();
  const [current, setCurrent] = useState('usuarios');
  const location = useLocation();

  useEffect(() => {
    const currentLocation = location.pathname.slice(1);
    const findKey = menuItems.find((i) => {
      if (i.children) {
        return i.children.find((j) => j.key === currentLocation);
      }
      return i.key === currentLocation;
    });
    if (findKey) {
      setCurrent(currentLocation);
    }
  }, []);

  if (loading) {
    return (
      <Row style={{ minHeight: '100vh', textAlign: 'center' }} justify="center" align="middle">
        <Col span={4} xs={24}>
          <Spin />
        </Col>
      </Row>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout className="layout">
      <Header>
        <Row>
          <Col sm={1} xs={0}>
            <a className="logo" href={'/envios'} />
          </Col>
          <Col sm={21}>
            <Menu theme="light" mode="horizontal" items={menuItems} selectedKeys={[current]} />
          </Col>
          <Col sm={2}>
            <Menu
              theme="light"
              mode="horizontal"
              selectable={false}
              items={[
                {
                  label: (
                    <Space onClick={logout}>
                      <PoweroffOutlined />
                      <span>Cerrar Sesión</span>
                    </Space>
                  ),
                  key: 'logout',
                },
              ]}
            />
          </Col>
        </Row>
      </Header>
      <Content style={{ padding: '20px 50px', background: '#eff3f8' }}>
        <Outlet />
      </Content>
    </Layout>
  );
}
