import axios, { purgeQueryParams } from './axios';
import { components, paths } from './schema';

export const getZipCodes = async (
  params: paths['/user-client/zipcode/list']['get']['parameters']['query'],
): Promise<components['schemas']['ZipcodeListResponse']['data']> => {
  const data = await axios.get<components['schemas']['ZipcodeListResponse']>(
    `/user-client/zipcode/list?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const getZipCode = async (zipCodeId?: string): Promise<components['schemas']['GetZipcodeResponse']['data']> => {
  if (!zipCodeId) return await Promise.reject(new Error());
  const data = await axios.get<components['schemas']['GetZipcodeResponse']>(`/user-client/zipcode/${zipCodeId}`);
  return data.data.data;
};
