import { FilterFilled } from '@ant-design/icons';
import { Space, Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/es/table';
import React from 'react';
import DataTableFilter, { AvailableFilterTypes, OnFilterApplyCallback, OnOrderApplyCallback } from './DataTableFilter';

interface CustomColumnType<T> extends ColumnType<T> {
  columnType: AvailableFilterTypes;
  hideFilter?: boolean;
  customCellClass?: (r: T) => string | undefined;
  customCellTooltip?: (r: T) => string | undefined;
  customFormat?: (r: T) => string;
  trimValueToMax?: number | undefined;
}

export interface ColumnGroupType<RecordType> extends Omit<CustomColumnType<RecordType>, 'dataIndex'> {
  children: ColumnsType<RecordType>;
}

export type ColumnsType<RecordType = unknown> = Array<ColumnGroupType<RecordType> | CustomColumnType<RecordType>>;

export interface DataTableFilterCallback extends OnFilterApplyCallback {
  columnType: AvailableFilterTypes;
  columnName: string;
}

export interface DataTableOrderCallback extends OnOrderApplyCallback {
  columnType: AvailableFilterTypes;
  columnName: string;
}
export interface PaginationData {
  pageSize: number;
  currentPage: number;
  count: number;
}

interface DataTableProps<T> {
  results: T[];
  columns: ColumnsType<T>;
  handleFilters: (data: DataTableFilterCallback) => void;
  handleOrder: (data: DataTableOrderCallback) => void;
  activeFilters: string | undefined; // JSON stringify string
  activeOrders: string | undefined; // JSON stringify string
  paginationData: PaginationData;
  handlePageChange: (page: number) => void;
  actions?: Array<(injectedProps: any) => JSX.Element>;
  onRowClick?: (record: T) => void;
}

export interface DataTableActionProps<T> {
  key: React.Key;
  record: T;
}

export default function DataTable<T extends object>({
  results,
  columns,
  handleFilters,
  handleOrder,
  handlePageChange,
  activeFilters,
  activeOrders,
  paginationData,
  actions,
  onRowClick,
}: DataTableProps<T>) {
  const getColumnProps = (
    key: React.Key,
    columnResults: string[],
    columnType: AvailableFilterTypes,
    hideFilter: boolean = false,
  ) => {
    if (hideFilter) return {};
    let tmpActiveFilters: DataTableFilterCallback[] = [];
    let tmpActiveOrders: DataTableOrderCallback[] = [];
    if (activeFilters) {
      tmpActiveFilters = JSON.parse(activeFilters);
    }
    if (activeOrders) {
      tmpActiveOrders = JSON.parse(activeOrders);
    }
    const columnActiveFilters = tmpActiveFilters.find((f: any) => f.columnName === (key as string));
    const columnActiveOrders = tmpActiveOrders.find((f: any) => f.columnName === (key as string));

    return {
      filterDropdown: () => {
        return (
          <DataTableFilter
            columnResults={columnResults}
            columnDataType={columnType}
            onFilterApply={(data) => handleFilters({ ...data, columnType, columnName: key as string })}
            onOrderApply={(data) => handleOrder({ ...data, columnType, columnName: key as string })}
            columnActiveFilters={columnActiveFilters}
            columnActiveOrders={columnActiveOrders}
          />
        );
      },
      filterIcon:
        columnActiveFilters !== undefined || columnActiveOrders !== undefined ? (
          <FilterFilled style={{ color: '#1677ff' }} />
        ) : (
          <FilterFilled />
        ),
    };
  };

  const localColumns: ColumnsType<T> = columns.map((c) => ({
    ...c,

    ...getColumnProps(
      c.key!,
      results.map((result) => result[c.key! as keyof T] as string),
      c.columnType,
      c.hideFilter,
    ),

    render: (text, record) => {
      const styles: React.CSSProperties = {};

      let customClass = '';
      if (c.customCellClass) {
        const colClass = c.customCellClass(record);
        if (colClass) {
          customClass = colClass;
        }
      }

      let columnText = text;
      if (c.trimValueToMax) {
        columnText = trimStringWithEllipsis(text, c.trimValueToMax);
      }
      if (c.customFormat) {
        columnText = c.customFormat(record);
      }

      if (onRowClick) {
        if (c.customCellTooltip) {
          const tooltipTitle = c.customCellTooltip(record);
          return (
            <Tooltip title={tooltipTitle}>
              <div
                style={styles}
                className={`ant-table-cell-override ${customClass}`}
                onClick={() => onRowClick(record)}
              >
                {columnText}
              </div>
            </Tooltip>
          );
        }
        return (
          <div style={styles} className={`ant-table-cell-override ${customClass}`} onClick={() => onRowClick(record)}>
            {columnText}
          </div>
        );
      }

      if (c.customCellTooltip) {
        const tooltipTitle = c.customCellTooltip(record);
        return (
          <Tooltip title={tooltipTitle}>
            <div style={styles} className={`ant-table-cell-override-non-clickable ${customClass}`}>
              {columnText}
            </div>
          </Tooltip>
        );
      }
      return (
        <div style={styles} className={`ant-table-cell-override-non-clickable ${customClass}`}>
          {columnText}
        </div>
      );
    },
  }));

  if (actions?.length) {
    localColumns.push({
      title: 'Acciones',
      dataIndex: 'actions',
      key: 'actions',
      columnType: 'action',
      align: 'center',
      render: (_, record) => (
        <Space size={'middle'} style={{ padding: '1em' }}>
          {actions?.map((Action, k) => (
            <Action key={k} {...{ record }} />
          ))}
        </Space>
      ),
    });
  }

  const localResults = results.map((result) => ({
    ...result,
    key: result['id' as keyof T],
  }));

  return (
    <div>
      <Table
        columns={localColumns}
        dataSource={localResults}
        onChange={(pagination) => handlePageChange(pagination.current ?? 1)}
        size="small"
        pagination={{
          pageSize: paginationData.pageSize,
          current: paginationData.currentPage,
          total: paginationData.count,
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros`,
          showSizeChanger: false,
        }}
      />
    </div>
  );
}

export const serializeFilters = (currentFilter: string | undefined, filter: DataTableFilterCallback) => {
  let newFilter: any;
  const shouldDeleteFilter = filter.filterByCond === null && filter.filterByVal.length === 0;
  if (currentFilter === undefined) {
    if (shouldDeleteFilter) return undefined;
    return JSON.stringify([filter]);
  }
  const currentFilters = JSON.parse(currentFilter);
  if (shouldDeleteFilter) {
    newFilter = currentFilters.filter((item: any) => item.columnName !== filter.columnName);
  } else {
    const columnExist = currentFilters.find((item: any) => item.columnName === filter.columnName);
    // If it already exist, then just replace it, otherwise add it
    if (columnExist) {
      newFilter = currentFilters.map((item: any) => (item.columnName === filter.columnName ? filter : item));
    } else {
      newFilter = [...currentFilters, filter];
    }
  }
  return newFilter.length === 0 ? undefined : JSON.stringify(newFilter);
};

export const serializeOrders = (currentOrder: string | undefined, order: DataTableOrderCallback) => {
  let newOrder: any;
  const shouldDeleteOrder = order.order === null;
  if (currentOrder === undefined) {
    if (shouldDeleteOrder) return undefined;
    return JSON.stringify([order]);
  }
  const currentOrders = JSON.parse(currentOrder);
  if (shouldDeleteOrder) {
    newOrder = currentOrders.filter((item: any) => item.columnName !== order.columnName);
  } else {
    const columnExist = currentOrders.find((item: any) => item.columnName === order.columnName);
    // If it already exist, then just replace it, otherwise add it
    if (columnExist) {
      newOrder = currentOrders.map((item: any) => (item.columnName === order.columnName ? order : item));
    } else {
      newOrder = [...currentOrders, order];
    }
  }
  return newOrder.length === 0 ? undefined : JSON.stringify(newOrder);
};

export const trimStringWithEllipsis = (inputString: string, maxLength: number) => {
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    return inputString.slice(0, maxLength - 3) + '...';
  }
};
