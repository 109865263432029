import { components, paths } from './schema';
import axios, { purgeQueryParams } from './axios';

export const listProductKeys = async (
  params: paths['/user-client/sat/list/product-keys']['get']['parameters']['query'],
): Promise<components['schemas']['ProductKeysListResponse']['data']> => {
  const data = await axios.get<components['schemas']['ProductKeysListResponse']>(
    `/user-client/sat/list/product-keys?${new URLSearchParams(purgeQueryParams(params)).toString()}`,
  );
  return data.data.data;
};

export const getProductKey = async (
  productKey?: string,
): Promise<components['schemas']['SATProductResponse']['data']> => {
  if (!productKey) return await Promise.reject(new Error());
  const data = await axios.get<components['schemas']['SATProductResponse']>(
    `/user-client/sat/product-key/${productKey}`,
  );
  return data.data.data;
};

export const listUnitKeys = async (): Promise<components['schemas']['UnitKeysListResponse']['data']> => {
  const data = await axios.get<components['schemas']['UnitKeysListResponse']>(`/user-client/sat/list/unit-keys`);
  return data.data.data;
};

export const listFormaPago = async (): Promise<components['schemas']['FormaPagoListResponse']['data']> => {
  const data = await axios.get<components['schemas']['FormaPagoListResponse']>(`/user-client/sat/list/forma-pago`);
  return data.data.data;
};

export const listRegimenFiscal = async (): Promise<components['schemas']['RegimenFiscalListResponse']['data']> => {
  const data = await axios.get<components['schemas']['RegimenFiscalListResponse']>(
    `/user-client/sat/list/regimen-fiscal`,
  );
  return data.data.data;
};
