import axios from './axios';
import { components } from './schema';

export const createAddress = async (
  clientId: string,
  body: components['schemas']['CreateAddress'],
): Promise<components['schemas']['CreateAddressResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateAddressResponse']>(
    `/user-client/client/${clientId}/create-address`,
    body,
  );
  return data.data.data;
};

export const createContact = async (
  clientId: string,
  body: components['schemas']['CreateClientContact'],
): Promise<components['schemas']['CreateContactResponse']['data']> => {
  const data = await axios.post<components['schemas']['CreateContactResponse']>(
    `/user-client/client/${clientId}/create-contact`,
    body,
  );
  return data.data.data;
};
